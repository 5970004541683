import React from 'react';
import { combineClassNames } from '@utils/combineClassNames';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';

type ToggleProps = {
  value: boolean;
  label?: string;
  disabled?: boolean;
  onToggle?: () => void;
  onChange?: (value: boolean) => void;
};

const ToggleComponent = ({
  value,
  label,
  disabled = false,
  onToggle,
  onChange,
}: ToggleProps) => {
  const fullClassName = combineClassNames('Form__Toggle', {
    'Form__Toggle--disabled': disabled,
  });

  const onHandleToggle = onToggle || (onChange && (() => onChange(!value)));

  return (
    <div className={fullClassName}>
      <div
        className={`Form__Toggle__Input${value ? ' Form__Toggle__Input--active' : ''}`}
        onClick={onHandleToggle}
        role="checkbox"
      >
        <div className="Form__Toggle__Input__Ball" />
      </div>
      {label && <label onClick={onHandleToggle}>{label}</label>}
    </div>
  );
};

export const Toggle = ToggleComponent;

const fieldWrapper = <P extends Record<string, any>, C = React.ComponentType<any>>(component: C) => (
  props: BaseFieldProps & Omit<P, keyof WrappedFieldProps>,
) => (
  <Field {...props} component={component} />
);

export const ToggleInput = fieldWrapper(({
  input: { value, onChange },
  label,
  disabled,
}: WrappedFieldProps & ToggleProps) => {
  return (
    <ToggleComponent
      value={value}
      label={label}
      onChange={onChange}
      disabled={disabled}
    />
  );
});
